import React, { useState, useEffect } from 'react'
import sessionUtils from '../../utilities/SessionHandler'
import ModalComponent from '../../components/Private/ModalComponent'
import Navbar from '../../components/Private/Navbar'
import { useNavigate } from 'react-router-dom'

import fileService from '../../services/files'
import companyUserService from '../../services/companyUsers'
import businessRoleService from '../../services/businessroles'

//TODO: Indicaciones de avatar

export default function MyProfile() {

    const navigateTo = useNavigate()

    const [callbackData, setCallbackData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalBody, setModalBody] = useState('')

    const [companyUser, setCompanyUser] = useState(null)
    const [companyUserRole, setCompanyUserRole] = useState(null)
    let credentials = sessionUtils.getParsedCredentials()

    const [userAvatar, setUserAvatar] = useState(null)

    const [formData, setFormData] = useState({
        companyUserFullName: '',
        companyUserDNI: '',
        companyUserPhone: '',
        companyUserEmail: '',
        companyUserPassword: '',
        avatar_id: null,
    })

    const [fileLoading, setFileLoading] = useState(false)

    const [fileData, setFileData] = useState({
        file: ''
    })

    const handleCallbackOnHide = (data) => {
        navigateTo(data.destination)
    }

    const handleUserLoad = async () => {
        const result = await companyUserService.get(credentials.id)
        setCompanyUser(result)

        let loadedData = {
            ...result,
            companyUserPassword: ''
        }

        if (result?.avatar_id)
            handleFileImage(result)

        setFormData(loadedData)
    }

    const handleRoleLoad = async () => {
        const result = await businessRoleService.get(credentials.businessRole_id)
        setCompanyUserRole(result)
    }

    const handleFieldChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        const result = await companyUserService.update(credentials.id, formData)

        if (result && result.status === 200) {
            setModalTitle('Éxito')
            setModalBody('Se ha actualizado su perfil correctamente')
            setShowModal(true)

            const newUser = await companyUserService.get(credentials.id)
            sessionUtils.setCredentials(
                btoa(JSON.stringify(newUser))
            )

            setCallbackData({ destination: "/home" })
        } else {
            setModalTitle('Error')
            setModalBody(result.messages.error)
            setShowModal(true)

            setCallbackData({ destination: "/home" })
        }

    }

    const handleFileUpload = async (avatar) => {
        setFileLoading(true)
        setFileData({
            file: avatar
        })
        const fileResult = await fileService.create({
            file: avatar,
            type: 'avatar'
        })
        if (fileResult) {
            console.log(fileResult)
            await companyUserService.patch(credentials.id, {
                avatar_id: fileResult.id
            })
        }
        handleUserLoad()
        setFileLoading(false)
    }

    const handleFileDelete = async () => {
        setFileLoading(true)
        await companyUserService.patch(credentials.id, {
            avatar_id: null
        })
        handleUserLoad()
        setFileLoading(false)
    }

    const handleFileImage = async (item) => {
        const fileResult = await fileService.get(item.avatar_id)
        if (!fileResult || !fileResult.fileBase64) return alert(
            fileResult.fileErrorMessage
        )
        const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
        setUserAvatar(linkSource)
    }

    useEffect(() => {
        handleUserLoad()
        handleRoleLoad()
    }, [])

    return (
        <>
            <Navbar companyUser />
            <main className="theme-light">
                <div className="container">
                    <div className="row mb-4">
                        <div className='d-flex justify-content-start align-item-center'>
                            <div className="btn-group me-3">
                                <button className="btn btn-outline-primary px-3 py-2" type="button" onClick={
                                    () => navigateTo('/home')
                                }>
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                            </div>
                            <h3 className="page-title fw-bold mb-lg-0 mb-0">
                                Mi Perfil
                            </h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card bg-fondo-suave">
                                <div className="card-body p-4">
                                    <form action="">
                                        <div className="row">
                                            <div className="col-md-6 px-md-4 px-0">
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="">Nombre Completo</label>
                                                    <input
                                                        type="text"
                                                        name="companyUserFullName"
                                                        className="form-control form-control-lg rounded-pill"
                                                        placeholder="Ingrese su nombre completo"
                                                        value={formData.companyUserFullName}
                                                        onChange={handleFieldChange}
                                                    />
                                                </div>
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="">RUT</label>
                                                    <input
                                                        type="text"
                                                        name="companyUserDNI"
                                                        className="form-control form-control-lg rounded-pill"
                                                        placeholder="Ingrese su RUT"
                                                        value={formData.companyUserDNI}
                                                        onChange={handleFieldChange}
                                                    />
                                                </div>
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="">Contraseña</label>
                                                    <input
                                                        type="password"
                                                        name="companyUserPassword"
                                                        className="form-control form-control-lg rounded-pill"
                                                        placeholder="(Ingrese una contraseña nueva)"
                                                        value={formData.companyUserPassword}
                                                        onChange={handleFieldChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 px-md-4 px-0">
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="">Teléfono</label>
                                                    <input
                                                        type="text"
                                                        name="companyUserPhone"
                                                        className="form-control form-control-lg rounded-pill"
                                                        placeholder="Teléfono"
                                                        value={formData.companyUserPhone}
                                                        onChange={handleFieldChange}
                                                    />
                                                </div>
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="">Email</label>
                                                    <input
                                                        type="text"
                                                        name="companyUserEmail"
                                                        className="form-control form-control-lg rounded-pill"
                                                        placeholder="Ingrese su email"
                                                        value={formData.companyUserEmail}
                                                        onChange={handleFieldChange}
                                                    />
                                                </div>
                                                {
                                                    formData && (

                                                        formData?.avatar_id === null
                                                            ?
                                                            <div className='form-outline mb-4'>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    {
                                                                        fileLoading ? (
                                                                            <div className="spinner-border my-4" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="row">
                                                                                <div className='col-md-12'>
                                                                                    <label htmlFor='file' className='form-label'>Avatar</label>
                                                                                    <input
                                                                                        name='file'
                                                                                        id='file'
                                                                                        type='file'
                                                                                        className='form-control'
                                                                                        placeholder='File'
                                                                                        accept='.png, .jpg, .jpeg'
                                                                                        onChange={
                                                                                            (e) => {
                                                                                                handleFileUpload(e.target.files[0])
                                                                                            }
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className='col-md-12 text-end text-info mt-2'>
                                                                                    <p>Suba una imagen con tamaño no superior a 2MB</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='form-outline mb-4'>
                                                                <div className='row'>
                                                                    <div
                                                                        className='d-flex justify-content-center align-items-center'
                                                                        style={{
                                                                            maxWidth: '100%',
                                                                        }}>
                                                                        {
                                                                            userAvatar ? (
                                                                                <img
                                                                                    src={userAvatar}
                                                                                    alt='Avatar'
                                                                                    className='img-fluid my-4'
                                                                                />
                                                                            ) : (
                                                                                <div className="spinner-border my-4" role="status">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div
                                                                        className='d-flex justify-content-center align-items-center'
                                                                        style={{
                                                                            maxWidth: '100%',
                                                                        }}>
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-outline-danger mt-2'
                                                                            onClick={handleFileDelete}
                                                                        >
                                                                            Eliminar
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <button
                                                className="btn btn-primary px-3 py-2"
                                                type="button"
                                                onClick={handleFormSubmit}
                                            >
                                                Guardar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ModalComponent
                id='alertModal'
                title={modalTitle}
                show={showModal}
                setShow={setShowModal}
                callbackOnHide={handleCallbackOnHide}
                callbackData={callbackData}
            >
                <p>
                    {modalBody}
                </p>
                <div className='d-flex justify-content-end mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowModal(false)
                        }}
                    >
                        Ok
                    </button>
                </div>
            </ModalComponent>
        </>
    )
}
