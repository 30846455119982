import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import contestService from '../../../services/contests'
import applicationService from '../../../services/applications'

import sessionUtils from '../../../utilities/SessionHandler'

export default function List() {

  const navigateTo = useNavigate()
  let credentials = sessionUtils.getParsedCredentials()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar postulación')

  const [contestData, setContestData] = useState(null)
  const [listData, setListData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const handleDataDelete = async (itemId) => {
    await applicationService.remove(itemId)
    await handleDataLoad()
  }

  const handleDataLoad = async () => {
    if (!credentials) return navigateTo('/home')
    let result;
    switch (parseInt(credentials.businessRole_id)) {
      case 1:
        result = await applicationService.byCompany(credentials.company_id)
        break;
      case 2:
        result = await applicationService.byCompanyUser(credentials.id)
        break;
      default:
        return navigateTo('/home')
    }
    setListData(result)
  }

  const handleContestLoad = async () => {
    try {
      const result = await contestService.showActive()
      setContestData(result)
    } catch (error) {
      setContestData(false)
    }

  }

  useEffect(() => {
    handleContestLoad()
    handleDataLoad()
  }, [])

  return (
    <>
      <Navbar companyUser />
      {
        listData && <ListSection
          title='Postulaciones'
          hasSearch={true}
          hasYear={false}
          tabs={false}
          pagination={
            {
              itemsPerPage: 10,
              maxPagesInPagination: 5,
              currentPage: 1,
              prevPage: 'Anterior',
              nextPage: 'Siguiente',
              lastPage: 'Última',
            }
          }
          backRoute={
            {
              title: 'Volver',
              onClick: () => {
                navigateTo('/home')
              }
            }
          }
          actions={
            contestData.contestActivity === 'Online' && contestData !== false && {
              title: 'Crear postulación',
              onClick: () => {
                navigateTo('/application/create')
              }
            }
          }
          columns={
            [
              {
                title: 'Título de la Postulación',
                field: 'applicationTitle',
                type: 'text',
              },
              {
                title: 'Categoría',
                field: 'contestDescription',
                type: 'text',
              },
              {
                title: 'Enviado',
                field: 'applicationStatusEditedByCompanyFirstDateTime',
                type: 'date',
              },
              {
                title: 'Estado',
                field: 'applicationStatus',
                type: 'text',
              },
              {
                title: 'Acciones',
                field: 'actions',
                type: 'actions',
              },
            ]
          }
          rows={
            [
              ...listData.map((item, index) => {

                let actions = []

                if (contestData.contestActivity === 'Online' && item.applicationStatusDraft === 'Borrador') {
                  actions = [
                    {
                      className: 'btn',
                      icon: 'bi bi-pencil',
                      tooltip: 'Editar',
                      onClick: () => {
                        navigateTo('/application/edit/' + item.id)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-trash',
                      tooltip: 'Borrar',
                      onClick: () => {
                        setSelectedItemId(item.id)
                        setDeleteModalTitle('Borrar ' + item.applicationTitle)
                        setShowDeleteModal(true)
                      }
                    },
                  ]
                } 

                if (item.applicationStatusDraft === 'Enviada') {
                  actions = [
                    {
                      className: 'btn',
                      icon: 'bi bi-eye',
                      tooltip: 'Revisar',
                      onClick: () => {
                        navigateTo('/application/view/' + item.id)
                      }
                    },
                  ]
                } 
                
                if (item.applicationStatusDraft === 'Devuelta') {
                  actions = [
                    {
                      className: 'btn',
                      icon: 'bi bi-pencil',
                      tooltip: 'Editar',
                      onClick: () => {
                        navigateTo('/application/edit/' + item.id)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-trash',
                      tooltip: 'Borrar',
                      onClick: () => {
                        setSelectedItemId(item.id)
                        setDeleteModalTitle('Borrar ' + item.applicationTitle)
                        setShowDeleteModal(true)
                      }
                    },
                  ]
                }

                let itemStatus;

                switch (item.applicationStatusDraft) {
                  case 'Borrador':
                    itemStatus = {
                      text: item.applicationStatusDraft,
                      className: 'status-draft align-middle'
                    }
                    break;
                  case 'Enviada':
                    itemStatus = {
                      text: item.applicationStatusDraft,
                      className: 'status-send align-middle'
                    }
                    break;
                  case 'Devuelta':
                    itemStatus = {
                      text: item.applicationStatusDraft,
                      className: 'status-featured align-middle'
                    }
                    break;
                }

                let appSentDate = item.applicationStatusEditedByCompanyFirstDateTime;
                if (appSentDate) {
                  appSentDate = new Date(appSentDate)
                  appSentDate = appSentDate.toLocaleDateString('es-CL')
                } else {
                  appSentDate = '-'
                }
                appSentDate = {
                  text: appSentDate,
                  className: 'align-middle'
                }

                return {
                  applicationTitle: item.applicationTitle,
                  contestDescription: item.contest.contestDescription,
                  applicationStatusEditedByCompanyFirstDateTime: appSentDate,
                  applicationStatus: itemStatus,
                  actions: actions,
                }
              })
            ]
          }
        />
      }
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar esta postulación?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
