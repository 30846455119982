import React, { useEffect, useState } from 'react'
import IMG_ISOTIPO_CONCURSO from '../../assets/img/isotipo-concurso.png'
import { useNavigate } from 'react-router-dom';
import sessionUtils from '../../utilities/SessionHandler'
import companyUserService from '../../services/companyUsers';
import ModalComponent from '../../components/Private/ModalComponent';

export default function SignIn() {

  const navigateTo = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalBody, setModalBody] = useState('')

  const [formData, setFormData] = useState(
    {
      companyUserEmail: '',
      companyUserPassword: ''
    }
  )

  const handleRemoveCredentials = () => {
    sessionUtils.removeCredentials()
  }

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async () => {
    try {
      const result = await companyUserService.signIn(formData);

      if (result.status === 200) {
        sessionUtils.setCredentials(result.user)
      }

      navigateTo('/home')

    } catch (error) {
      setModalTitle('Error')
      setModalBody(error.response.data.messages.error)
      setShowModal(true)
    }
  }

  const renderSignInForm = () => {
    //set a gutter between the columns
    return (
      <>
        <div className="form-outline mb-4">
          <label
            className="form-label"
            htmlFor="companyUserEmail"
          >
            Email
          </label>
          <input
            type="text"
            name='companyUserEmail'
            id='companyUserEmail'
            className="form-control form-control-lg rounded-pill"
            placeholder="Ingrese su email"
            onChange={handleInputChange}
            value={formData.companyUserEmail}
          />
        </div>
        <div className="form-outline mb-4">
          <label
            className="form-label"
            htmlFor="companyUserPassword"
          >
            Contraseña
          </label>
          <input
            type="password"
            name='companyUserPassword'
            id='companyUserPassword'
            className="form-control form-control-lg rounded-pill"
            placeholder="Ingrese su contraseña"
            onChange={handleInputChange}
            value={formData.companyUserPassword}
          />
        </div>
        <input
          type="button"
          className="btn btn-lg btn-primary rounded-pill w-100 my-3 py-2 text-white"
          value="Iniciar Sesión"
          onClick={handleSubmit}
        />
      </>
    )
  }

  const btnPasswordRequest = () => {
    navigateTo('/password-request')
  }

  useEffect(() => {
    handleRemoveCredentials();
  }, []);

  return (

    <>
      <main id="welcome">
        <div className="container-fluid vh-100">
          <div className="row">
            <div className="col-md-5 d-flex align-items-center p-md-5 px-3 pt-5 pb-0 welcome-col">
              <div className="welcome-title mx-auto text-md-start text-center">
                <img src={IMG_ISOTIPO_CONCURSO}
                  alt="Concurso Buenas Practicas"
                  className="img-fluid mb-md-5 mb-3 isotipo-concurso"
                />
                <h1 className="fw-light text-white mx-auto">Bienvenido al <span className="fw-medium">Concurso Compartiendo Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
              </div>
            </div>
            <div className="col-md-7 d-flex align-items-center sign-in px-lg-5 pt-3 pb-5">
              <div className="card no-shadow m-auto p-4">
                <h3 className="card-title fw-bold mx-auto">Iniciar sesión</h3>
                <div className="card-body">
                  <form action="">
                    {renderSignInForm()}
                  </form>
                  <p className="text-center">
                    ¿Olvidó su Contraseña?
                    &nbsp;
                    <a
                      className='react-link'
                      onClick={btnPasswordRequest}
                    >
                      Ingrese aquí
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ModalComponent
        id='alertModal'
        title={modalTitle}
        show={showModal}
        setShow={setShowModal}
      >
        <p>
          {modalBody}
        </p>
        <div className='d-flex justify-content-end mt-4'>
          <button
            type='button'
            className='btn btn-secondary rounded-pill'
            onClick={() => {
              setShowModal(false)
            }}
          >
            Aceptar
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
