import React, { useState, useEffect } from 'react'
import sessionUtils from '../../utilities/SessionHandler'

import Navbar from '../../components/Private/Navbar'
import StatisticCard from '../../components/Private/Card/DashboardStatistics'
import NavigationCard from '../../components/Private/Card/DashboardNavigation'

import companyUserService from '../../services/companyUsers'
import businessRoleService from '../../services/businessroles'
import applicationService from '../../services/applications'

export default function Home() {

  const [companyUser, setCompanyUser] = useState(null)
  const [companyUserRole, setCompanyUserRole] = useState(null)
  const [applications, setApplications] = useState(
    {
      por_revisar: 0,
      pre_seleccionadas: 0,
      finalizadas: 0,
      destacadas: 0
    }
  )

  let credentials = sessionUtils.getParsedCredentials()

  const handleUserLoad = async () => {
    const result = await companyUserService.get(credentials.id)
    setCompanyUser(result)
  }

  const handleRoleLoad = async () => {
    const result = await businessRoleService.get(credentials.businessRole_id)
    setCompanyUserRole(result)
  }


  const handleApplicationsLoad = async () => {
    const result = await applicationService.byCompany(credentials.company_id)

    let por_revisar = 0
    let pre_seleccionadas = 0
    let finalizadas = 0
    let destacadas = 0

    const XResult = Object.entries(result).map(([key, value]) => value)

    XResult.forEach((application) => {
      if (application.applicationStatusAfterDraft === 'En revisión') {
        por_revisar++
      }
      else
        if (application.applicationStatusPreselection === 'En revisión') {
          pre_seleccionadas++
        }
        else
          if (application.applicationStatusFinalist === 'En revisión') {
            finalizadas++
          }
          else
            if (application.applicationStatusFinal === 'Destacada') {
              destacadas++
            }
    })

    setApplications({
      por_revisar,
      pre_seleccionadas,
      finalizadas,
      destacadas
    })

  }

  useEffect(() => {
    handleUserLoad()
    handleRoleLoad()
    handleApplicationsLoad()
  }, [])

  return (
    <>
      <Navbar companyUser />
      <main className="theme-light">
        <div className="container">
          {
            companyUserRole && [1, 2].includes(parseInt(companyUserRole.id)) && (
              <>
                <div className="row row-cols-lg-4 row-cols-2 g-3 mb-lg-5 mb-4 text-center">
                  <div className="col">
                    <StatisticCard
                      classType='status-send'
                      title='0'
                      text='Revisar'
                    />
                  </div>
                  <div className="col">
                    <StatisticCard
                      classType='status-draft'
                      title='0'
                      text='Pre-seleccionadas'
                    />
                  </div>
                  <div className="col">
                    <StatisticCard
                      classType='status-approved'
                      title='0'
                      text='Finalizadas'
                    />
                  </div>
                  <div className="col">
                    <StatisticCard
                      classType='status-featured'
                      title='0'
                      text='Destacadas'
                    />
                  </div>
                </div>
                <div className="row row-cols-lg-3 row-cols-1 g-4 mb-5">
                  {
                    [1].includes(parseInt(companyUserRole.id)) && (
                      <div className="col">
                        <NavigationCard
                          icon={<i className="bi bi-people-fill card-icon"></i>}
                          title='Administrador de Usuarios'
                          route='/companyusers'
                        />
                      </div>
                    )
                  }
                  <div className="col">
                    <NavigationCard
                      icon={<i className="bi bi-folder-fill card-icon"></i>}
                      title='Postulaciones'
                      route='/applications'
                    />
                  </div>
                  <div className="card rounded no-shadow py-3">
                    <div className="card-body">
                      <i className="bi bi-envelope-at-fill card-icon"></i>
                      <h4 className="h5 fw-medium my-4">
                        Contacto
                      </h4>
                      <div className="row">
                        <div className="col mb-2">
                          <a href="#" onClick={
                            () => {
                              window.open('https://buenaspracticasensst.cl/contact-us', '_blank')
                            }
                          }>
                            Web de Contacto
                          </a>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <a href="mailto:contacto@buenaspracticasensst.cl?Subject=Contacto%20Empresa" target='_top'>
                            contacto@buenaspracticasensst.cl
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    <div className="col">
                      <NavigationCard
                        icon={<i className="bi bi-ticket-detailed card-icon"></i>}
                        title='Tickets'
                        route='/tickets'
                      />
                    </div>
                  }
                </div>
              </>
            )
          }
        </div>
      </main>
    </>
  )
}
