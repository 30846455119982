import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import sessionUtils from '../../../utilities/SessionHandler'
import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'
import companyUserService from '../../../services/companyUsers'
import ticketService from '../../../services/tickets'

export default function List() {

  const navigateTo = useNavigate()

  let credentials = sessionUtils.getParsedCredentials()

  const [isLoading, setIsLoading] = useState(true)

  const [companyUser, setCompanyUser] = useState(null)

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorModalTitle, setErrorModalTitle] = useState('Error')
  const [errorMessage, setErrorMessage] = useState('')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar ticket')

  const [listData, setListData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [formData, setFormData] = useState({
    companyUser_id: credentials.id,
    company_id: credentials.company_id,
    application_id: null,
    user_id: null,
    ticketStatus: 'creado',
    ticketTitle: '',
    ticketDescription: '',
  })

  const handleUserLoad = async () => {

    const result = await companyUserService.get(credentials.id)
    setCompanyUser(result)
  }

  const handleFieldChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  useEffect(() => {

  }, [formData])

  const handleDataDelete = async (itemId) => {
    await ticketService.remove(itemId)
    await handleDataLoad()
  }

  const handleDataLoad = async () => {
    const result = await ticketService.byCompanyUser(credentials.id)

    if (result.length === 0) {
      setListData([])
      setIsLoading(false)
      return
    }

    setListData(result)
    setIsLoading(false)
  }

  useEffect(() => {
    handleUserLoad()
    handleDataLoad()
  }, [])

  return (
    <>
      <Navbar companyUser />
      {
        isLoading &&
        <>
          <div className='vh-100 d-flex justify-content-center align-items-center'>
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      }
      {
        listData
        &&
        <>
          {
            !isLoading && <ListSection
              pagination={
                {
                  itemsPerPage: 5,
                  maxPagesInPagination: 5,
                  currentPage: 1,
                  prevPage: 'Anterior',
                  nextPage: 'Siguiente',
                  lastPage: 'Última',
                }
              }
              title={`Tickets`}
              hasSearch={true}
              hasYear={false}
              tabs={false}
              backRoute={
                {
                  title: 'Volver',
                  onClick: () => {
                    navigateTo('/')
                  }
                }
              }
              actions={
                {
                  title: 'Nuevo ticket',
                  onClick: () => {
                    navigateTo('/ticket/create')
                  }
                }
              }
              columns={
                [
                  {
                    title: 'Nombre',
                    field: 'ticketTitle',
                    type: 'text',
                  },
                  {
                    title: 'Estado',
                    field: 'ticketStatus',
                    type: 'text',
                  },
                  {
                    title: 'Acciones',
                    field: 'actions',
                    type: 'actions',
                  },
                ]
              }
              rows={
                [
                  ...listData.map((item, index) => {

                    let newActions = [];

                    if (item.ticketStatus === 'creado' || item.ticketStatus === 'borrador') {
                      newActions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo(`/ticket/edit/${item.id}`)
                          }
                        }
                      )
                      newActions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-trash',
                          tooltip: 'Borrar',
                          onClick: () => {
                            setSelectedItemId(item.id)
                          }
                        }
                      )
                    }

                    if (item.ticketStatus === 'en revisión') {
                      newActions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo(`/ticket/edit/${item.id}`)
                          }
                        }
                      )
                      newActions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-trash',
                          tooltip: 'Borrar',
                          onClick: () => {
                            setSelectedItemId(item.id)
                          }
                        }
                      )
                    }

                    if (item.ticketStatus === 'resuelto') {
                      newActions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Ver',
                          onClick: () => {
                            navigateTo(`/ticket/edit/${item.id}`)
                          }
                        }
                      )
                    }

                    return {
                      ticketTitle: item.ticketTitle,
                      //status must be first letter uppercase
                      ticketStatus: {
                        text: item.ticketStatus.charAt(0).toUpperCase() + item.ticketStatus.slice(1),
                        className: `${item.ticketStatus === 'creado' || item.ticketStatus === 'borrador' ? 'align-middle text-secondary' : item.ticketStatus === 'en revisión' ? 'align-middle text-info' : 'align-middle text-success'}`
                      },
                      actions: newActions,
                    }
                  })
                ]
              }
            />
          }
        </>
      }
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar este ticket?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='errorModal'
        title={errorModalTitle}
        show={showErrorModal}
        setShow={setShowErrorModal}
        headerClass='bg-danger text-white'
        modalSize='modal-lg'
      >
        <p>{errorMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowErrorModal(false)
              setSelectedItemId(null)
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
