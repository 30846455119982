import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import Form from '../Application/Form/Index'

import contestService from '../../../services/contests'
import applicationService from '../../../services/applications'
import applicationCategoryGroupService from '../../../services/applicationCategoryGroups'
import applicationFileService from '../../../services/applicationFiles'

export default function Edit() {

  const navigateTo = useNavigate()

  const [isLoading, setIsLoading] = useState(true)

  const { application_id } = useParams()

  const [contestData, setContestData] = useState(null)
  const [itemData, setItemData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const handleDataLoad = async () => {
    let newResult;
    try {
      const result = await applicationService.get(application_id)
      const applicationResult = await applicationCategoryGroupService.byApplication(application_id)
      const filesResult = await applicationFileService.byApplication(application_id)
      newResult = {
        ...result,
        dynamic: [
          ...applicationResult.map((item) => {
            return {
              categoryGroupId: item.categoryGroup.id,
              category: item.categoryGroup.category
            }
          })
        ],
        files: [
          ...filesResult
        ]
      }
      
    } catch (error) {
    }
    setItemData(newResult)
    setIsLoading(false)
  }

  const handleContestLoad = async () => {
    try {
      const result = await contestService.showActive()
      setContestData(result)
    } catch (error) {
      setContestData(false)
    }
  }

  useEffect(() => {
    handleContestLoad()
    handleDataLoad()
  }, [])

  return (
    <>
      <Navbar companyUser />
      {
        isLoading &&
        <>
          <div className='vh-100 d-flex justify-content-center align-items-center'>
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      }
      {
        itemData && contestData && !isLoading && <Form
          contestData={contestData}
          formTitle="Editar postulación"
          backRoute={`/applications`}
          itemData={itemData}
        />
      }
    </>
  )
}
