import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalComponent from '../../../../components/Private/ModalComponent'
import categoryGroupService from '../../../../services/categorygroups'
import categoryService from '../../../../services/categories'
import tagService from '../../../../services/tags'
import applicationService from '../../../../services/applications'
import fileService from '../../../../services/files'
import applicationFileService from '../../../../services/applicationFiles'
import applicationCategoryGroupService from '../../../../services/applicationCategoryGroups'

export default function Index(
  {
    contestData,
    formTitle,
    backRoute,
    itemData = null,
    readOnly = false,
  }
) {

  const navigateTo = useNavigate()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorModalTitle, setErrorModalTitle] = useState('Error')
  const [errorMessage, setErrorMessage] = useState('')

  const [showPreOperationModal, setShowPreOperationModal] = useState(false)
  const [preOperationModalTitle, setPreOperationModalTitle] = useState('Confirmación')
  const [preOperationModalMessage, setPreOperationModalMessage] = useState('¿Está seguro de querer guardar y enviar?')

  const [callbackData, setCallbackData] = useState(null)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [saveModalTitle, setSaveModalTitle] = useState('Éxito')
  const [showSaveAndSendModal, setShowSaveAndSendModal] = useState(false)
  const [saveAndSendModalTitle, setSaveAndSendModalTitle] = useState('Éxito')
  const [dynamicFormList, setDynamicFormList] = useState([])

  const [formData, setFormData] = useState({
    applicationTitle: itemData?.applicationTitle || '',
    applicationDescription: itemData?.applicationDescription || '',
    applicationBenefits: itemData?.applicationBenefits || '',
    applicationImplementationDate: itemData?.applicationImplementationDate || '',
    files: itemData.files || [],
    dynamic: [],
  })

  const handleCategoryLoad = async () => {
    const result = await categoryGroupService.byContest(contestData.id)
    setDynamicFormList(result)
    let dynamic = []
    result.forEach(async (categoryGroup, indexCG) => {
      dynamic.push({
        categoryGroupId: categoryGroup.id,
        category: {}
      })
    })
    if (itemData?.dynamic) {
      itemData.dynamic.forEach((item) => {
        const dynamicIndex = dynamic.findIndex((item2) => item2.categoryGroupId === item.categoryGroupId)
        dynamic[dynamicIndex] = item
      })
    }
    setFormData({
      ...formData,
      dynamic: dynamic
    })
  }

  const handleFormChange = (event) => {

    const { name, value } = event.target
    const inputData = event.target.getAttribute('input-data')
    const inputTarget = event.target.getAttribute('input-target')
    const categoryGroupId = event.target.getAttribute('category-group-id')

    if (inputData === 'static') {
      setFormData({
        ...formData,
        [name]: value
      })
    } else if (inputData === 'dynamic') {
      if (inputTarget === 'categories') {
        const dynamicIndex = formData.dynamic.findIndex((item) => item.categoryGroupId === categoryGroupId)
        const dynamicCopy = [...formData.dynamic]
        if (dynamicCopy.length > 0) {
          dynamicCopy[dynamicIndex].category = {
            categoryId: value,
            tags: []
          }
        } else {
          dynamicCopy.push({
            categoryGroupId: categoryGroupId,
            category: {
              categoryId: value,
              tags: []
            }
          })
        }
        setFormData({
          ...formData,
          dynamic: dynamicCopy
        })
      } else if (inputTarget === 'tags') {
        const dynamicIndex = formData.dynamic.findIndex((item) => item.categoryGroupId === categoryGroupId)
        const dynamicCopy = [...formData.dynamic]
        const categoryIndex = dynamicCopy[dynamicIndex].category.tags.findIndex((item) => item === value)

        const categoryType = dynamicFormList
          .find((item) => item.id === categoryGroupId)
          .categories.find((item) => item.id === dynamicCopy[dynamicIndex].category.categoryId).categoryType

        if (categoryType === 'multiple') {
          if (categoryIndex === -1) {
            dynamicCopy[dynamicIndex].category.tags.push(value)
          } else {
            dynamicCopy[dynamicIndex].category.tags.splice(categoryIndex, 1)
          }
        } else {
          dynamicCopy[dynamicIndex].category.tags = [value]
        }

        setFormData({
          ...formData,
          dynamic: dynamicCopy
        })
      }
    }

  }

  const handleFormSave = async () => {
    let newFormData;
    if(itemData.applicationStatusDraft === "Devuelta"){
      newFormData = {
        ...formData,
      }
    }
    else {
      newFormData = {
        ...formData,
        applicationStatusDraft: "Borrador",
        applicationStatusAfterDraft: "",
        applicationStatusEditedByCompany: "",
        applicationStatusEditedByAdmin: "",
      }
    }
    try {
      await applicationService.update(itemData.id, newFormData)
      await applicationCategoryGroupService.create({
        application_id: itemData.id,
        categoryGroups: formData.dynamic
      })
      setShowSaveModal(true)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }

  }

  const handleFormSaveAndSend = async () => {
    let editadaPorEmpresa = itemData.applicationStatusDraft === "Devuelta"
    let newFormData = {
      ...formData,
      applicationStatusDraft: "Enviada",
      applicationStatusAfterDraft: "En revisión",
      applicationStatusEditedByCompany: editadaPorEmpresa ? "Editada" : "No editada",
      applicationStatusEditedByAdmin: "No editada",
    }
    try {
      await applicationService.update(itemData.id, newFormData)
      await applicationCategoryGroupService.create({
        application_id: itemData.id,
        categoryGroups: formData.dynamic
      })
      setCallbackData({ destination: "/applications" })
      setShowSaveAndSendModal(true)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleCallbackOnHide = (data) => {
    navigateTo(data.destination)
  }

  const handleFileUpload = async (event) => {

    try {
      if (event.target.files.length > 0) {
        let fileName = event.target.files[0].name

        const newFile = await fileService.create({
          file: event.target.files[0],
        })

        if (newFile?.error === true) {
          alert("Error al subir el archivo: " + newFile.message)
          return
        }

        let newApplicationFile = await applicationFileService.create({
          application_id: itemData.id,
          applicationFileName: fileName,
          file_id: newFile.id
        })
        let newFiles = [...formData.files]
        newFiles.push(newApplicationFile)
        setFormData({
          ...formData,
          files: newFiles
        })

      }
    } catch (error) {
      alert("Error al subir el archivo")
    }
  }

  const handleFileDownload = async (item) => {
    const fileResult = await fileService.get(item.file_id)
    if (!fileResult || !fileResult.fileBase64) return alert(
      fileResult.fileErrorMessage
    )
    const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
    const downloadLink = document.createElement("a")
    const fileName = item.applicationFileName
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const handleFileDelete = async (item) => {
    await applicationFileService.remove(item.id)
    let newFiles = [...formData.files]
    newFiles.splice(newFiles.findIndex((file) => file.id === item.id), 1)
    setFormData({
      ...formData,
      files: newFiles
    })
  }

  useEffect(() => {
    handleCategoryLoad()
  }, [])

  useEffect(() => {
    if (itemData) {
      setFormData({
        ...formData,
        applicationImplementationDate: itemData.applicationImplementationDate,
        applicationTitle: itemData.applicationTitle,
        applicationDescription: itemData.applicationDescription,
        applicationBenefits: itemData.applicationBenefits,
      })
    }
  }, [])

  useEffect(() => {
    //console.log(dynamicFormList)
  }, [dynamicFormList])

  useEffect(() => {
    //console.log(formData)
  }, [formData])

  return (
    <>
      <main className="theme-light">
        <div className="container">
          <div className="row mb-4">
            {
              itemData.applicationStatusRejectMotive &&
              <div className="col-md-12 order-2 order-lg-1 d-flex align-items-center justify-content-between">
                <div className="alert alert-danger w-100" role="alert">
                  <strong>Motivo de rechazo: </strong>{itemData.applicationStatusRejectMotive}
                </div>
              </div>
            }
            {
              itemData.applicationStatusReturnMotive &&
              <div className="col-md-12 order-2 order-lg-1 d-flex align-items-center justify-content-between">
                <div className="alert alert-warning w-100" role="alert">
                  <strong>Motivo de devolución: </strong>{itemData.applicationStatusReturnMotive}
                </div>
              </div>
            }
            <div className="col-md-12 order-2 order-lg-1 d-flex align-items-center justify-content-between">
              <div className='d-flex justify-content-start'>
                {
                  <div className="btn-group mt-lg-0 mt-4 me-3">
                    <button className="btn btn-outline-primary px-3 py-2" type="button" onClick={
                      () => {
                        navigateTo(backRoute)
                      }
                    }>
                      <i className="fas fa-arrow-left"></i>
                    </button>
                  </div>
                }
                <h3 className="page-title fw-bold mb-lg-0 mb-4">
                  {formTitle}
                </h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card bg-fondo-suave">
                <div className="card-body p-4">
                  <form action="">
                    <div className="row">
                      <div className="col-md-6 px-md-4 px-0">
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="">Título de la Buena Práctica</label>
                          <input
                            type="text"
                            className="form-control form-control-lg rounded-pill"
                            placeholder="Ingrese su nombre completo"
                            input-data={`static`}
                            id="applicationTitle"
                            name="applicationTitle"
                            value={formData.applicationTitle}
                            onChange={handleFormChange}
                            disabled={readOnly}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="">Descripción</label>
                          <textarea
                            cols="30"
                            rows="5"
                            className="form-control form-control-lg"
                            placeholder="Describe la práctica aplicada"
                            input-data={`static`}
                            id="applicationDescription"
                            name="applicationDescription"
                            value={formData.applicationDescription}
                            onChange={handleFormChange}
                            disabled={readOnly}
                          >
                          </textarea>
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="">Fecha de Implementación</label>
                          <input
                            type="date"
                            className="form-control form-control-lg rounded-pill"
                            placeholder=""
                            input-data={`static`}
                            id="applicationImplementationDate"
                            name="applicationImplementationDate"
                            value={formData.applicationImplementationDate || ''}
                            onChange={handleFormChange}
                            disabled={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 px-md-4 px-0">
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="">Beneficios</label>
                          <textarea cols="30"
                            rows="5"
                            className="form-control form-control-lg"
                            placeholder="Describe los beneficios de la buena práctica aplicada"
                            input-data={`static`}
                            id="applicationBenefits"
                            name="applicationBenefits"
                            value={formData.applicationBenefits}
                            onChange={handleFormChange}
                            disabled={readOnly}
                          >
                          </textarea>
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="">Antecedentes complementarios</label>
                          {
                            !readOnly && <div className="input-group mb-3">
                              <input
                                type="file"
                                className="form-control form-control-lg rounded-pill"
                                id="applicationFile"
                                name="applicationFile"
                                accept='.pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .gif'
                                value={``}
                                onChange={handleFileUpload}
                                disabled={readOnly}
                              />
                            </div>
                          }
                        </div>

                        <div className="form-outline mb-4">
                          {
                            formData.files.length > 0 &&

                            formData.files.map((item, index) => {
                              return (
                                <div className="mb-3" key={index}>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {
                                      !readOnly && <a
                                        onClick={
                                          () => handleFileDelete(item)
                                        }
                                        className='react-link btn btn-outline-danger me-4'
                                      >
                                        <i className="bi bi-trash"></i>
                                      </a>
                                    }
                                    <a
                                      onClick={
                                        () => handleFileDownload(item)
                                      }
                                      className="react-link"
                                    >
                                      {item.applicationFileName}
                                    </a>
                                  </div>
                                </div>
                              )
                            }
                            )
                          }
                        </div>
                      </div>
                    </div>
                    {
                      dynamicFormList.length > 0 &&
                      <div className="row">
                        {
                          dynamicFormList.map((categoryGroup, indexCG) => {
                            return (
                              <div className="col-md-6 px-md-4 px-0" key={indexCG}>
                                <div className="form-outline mb-2">
                                  <label className="form-label" htmlFor="">{categoryGroup.categoryGroupName}</label>
                                  <select
                                    disabled={readOnly}
                                    className="form-select form-select-lg"
                                    id={`categoryGroup${categoryGroup.id}-category`}
                                    name={`categoryGroup${categoryGroup.id}-category`}
                                    input-data={`dynamic`}
                                    input-target={`categories`}
                                    category-group-id={categoryGroup.id}
                                    value={
                                      formData.dynamic.find(
                                        dynamic => dynamic.categoryGroupId === categoryGroup.id
                                      )?.category?.categoryId
                                    }
                                    onChange={handleFormChange}
                                  >
                                    <option value="">Seleccione una opción</option>
                                    {
                                      categoryGroup.categories.map((category, indexC) => {
                                        return (
                                          <option
                                            value={category.id}
                                            key={indexC}
                                            category-group-id={categoryGroup.id}
                                            category-id={category.id}
                                          >
                                            {category.categoryName}
                                          </option>
                                        )
                                      })
                                    }
                                  </select>
                                </div>
                                {
                                  formData.dynamic.find(
                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                  )?.category?.categoryId &&
                                  <div className="form-outline mb-4">
                                    {
                                      dynamicFormList[indexCG].categories.find(
                                        category => category.id === formData.dynamic.find(
                                          dynamic => dynamic.categoryGroupId === categoryGroup.id
                                        ).category?.categoryId
                                      ).categoryType === 'multiple' && <div>
                                        <p className='text-helper text-primary text-start'>Puedes elegir múltiples opciones</p>
                                      </div>
                                    }
                                    {
                                      dynamicFormList[indexCG].categories.find(
                                        category => category.id === formData.dynamic.find(
                                          dynamic => dynamic.categoryGroupId === categoryGroup.id
                                        ).category?.categoryId
                                      ).categoryType === 'simple' && <div>
                                        <p className='text-helper text-primary text-start'>Puedes elegir una opción</p>
                                      </div>
                                    }
                                    {
                                      dynamicFormList[indexCG].categories.find(
                                        category => category.id === formData.dynamic.find(
                                          dynamic => dynamic.categoryGroupId === categoryGroup.id
                                        ).category?.categoryId
                                      ).categoryType === 'multiple' &&
                                      dynamicFormList[indexCG].categories.find(
                                        category => category.id === formData.dynamic.find(
                                          dynamic => dynamic.categoryGroupId === categoryGroup.id
                                        ).category?.categoryId
                                      ).tags.map((tag, indexT) => {
                                        return (
                                          <div className="form-check" key={indexT}>
                                            <input
                                              disabled={readOnly}
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`categoryGroup${categoryGroup.id}-tag${tag.id}`}
                                              name={`categoryGroup${categoryGroup.id}-tag${tag.id}`}
                                              value={tag.id}
                                              category-group-id={categoryGroup.id}
                                              category-id={formData.dynamic.find(
                                                dynamic => dynamic.categoryGroupId === categoryGroup.id
                                              )?.category?.categoryId}
                                              onChange={handleFormChange}
                                              input-data={`dynamic`}
                                              input-target={`tags`}
                                              checked={
                                                formData.dynamic.find(
                                                  dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                )?.category?.tags.includes(tag.id)
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`categoryGroup${categoryGroup.id}-tag${tag.id}`}
                                            >
                                              {tag.tagName}
                                            </label>
                                          </div>
                                        )
                                      })
                                    }
                                    {
                                      dynamicFormList[indexCG].categories.find(
                                        category => category.id === formData.dynamic.find(
                                          dynamic => dynamic.categoryGroupId === categoryGroup.id
                                        ).category?.categoryId
                                      ).categoryType === 'simple' &&
                                      dynamicFormList[indexCG].categories.find(
                                        category => category.id === formData.dynamic.find(
                                          dynamic => dynamic.categoryGroupId === categoryGroup.id
                                        ).category?.categoryId
                                      ).tags.map((tag, indexT) => {
                                        return (
                                          <div className="form-check" key={indexT}>
                                            <input
                                              disabled={readOnly}
                                              className="form-check-input"
                                              type="radio"
                                              name={`categoryGroup${categoryGroup.id}-tag`}
                                              id={`categoryGroup${categoryGroup.id}-tag${tag.id}`}
                                              value={tag.id}
                                              category-group-id={categoryGroup.id}
                                              category-id={formData.dynamic.find(
                                                dynamic => dynamic.categoryGroupId === categoryGroup.id
                                              ).category?.categoryId}
                                              onChange={handleFormChange}
                                              input-data={`dynamic`}
                                              input-target={`tags`}
                                              checked={
                                                formData.dynamic.find(
                                                  dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                ).category?.tags.includes(tag.id)
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`categoryGroup${categoryGroup.id}-tag${tag.id}`}
                                            >
                                              {tag.tagName}
                                            </label>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                    {
                      !readOnly && <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-outline-primary px-3 py-2 me-2"
                          type="button"
                          onClick={handleFormSave}
                        >
                          Guardar cambios
                        </button>
                        <button
                          className="btn btn-send px-3 py-2"
                          type="button"
                          onClick={
                            () => {
                              setShowPreOperationModal(true)
                            }
                          }
                        >
                          Guardar y Enviar
                        </button>
                      </div>
                    }

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ModalComponent
        id='saveModal'
        title={saveModalTitle}
        show={showSaveModal}
        setShow={setShowSaveModal}
      >
        <div className='d-flex justify-content-between mt-4'>
          <p>
            La postulación ha sido guardada con éxito.
          </p>
        </div>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowSaveModal(false)
            }}
          >
            Continuar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='saveAndSendModal'
        title={saveAndSendModalTitle}
        show={showSaveAndSendModal}
        setShow={setShowSaveAndSendModal}
        callbackOnHide={handleCallbackOnHide}
        callbackData={callbackData}
      >
        <div className='d-flex justify-content-between mt-4'>
          <p>
            La postulación ha sido guardada y enviada con éxito.
          </p>
        </div>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowSaveAndSendModal(false)
            }}
          >
            Continuar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='errorModal'
        title={errorModalTitle}
        show={showErrorModal}
        setShow={setShowErrorModal}
        headerClass='bg-danger text-white'
        modalSize='modal-lg'
      >
        <p>{errorMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowErrorModal(false)
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='preOperationModal'
        title={preOperationModalTitle}
        show={showPreOperationModal}
        setShow={setShowPreOperationModal}
        headerClass='bg-primary text-white'
        modalSize='modal-lg'
      >
        <p>{preOperationModalMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowPreOperationModal(false)
            }}
          >
            No
          </button>
          <button
            type='button'
            className='btn btn-outline-primary'
            onClick={() => {
              setShowPreOperationModal(false)
              handleFormSaveAndSend()
            }}
          >
            Sí
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
