import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalComponent from '../../../../components/Private/ModalComponent'

import ticketService from '../../../../services/tickets'
import ticketResponseService from '../../../../services/ticketResponses'
import ticketFileService from '../../../../services/ticketFiles'
import fileService from '../../../../services/files'

export default function Index(
    {
        contestData,
        formTitle,
        backRoute,
        itemData = null,
        readOnly = false,
    }
) {

    const navigateTo = useNavigate()

    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorModalTitle, setErrorModalTitle] = useState('Error')
    const [errorMessage, setErrorMessage] = useState('')

    const [showPreOperationModal, setShowPreOperationModal] = useState(false)
    const [preOperationModalTitle, setPreOperationModalTitle] = useState('Confirmación')
    const [preOperationModalMessage, setPreOperationModalMessage] = useState('¿Está seguro de querer guardar y enviar?')

    const [callbackData, setCallbackData] = useState(null)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [saveModalTitle, setSaveModalTitle] = useState('Éxito')
    const [showSaveAndSendModal, setShowSaveAndSendModal] = useState(false)
    const [saveAndSendModalTitle, setSaveAndSendModalTitle] = useState('Éxito')
    const [dynamicFormList, setDynamicFormList] = useState([])

    const [showRespondModal, setShowRespondModal] = useState(false)
    const [showSendResponseModal, setShowSendResponseModal] = useState(false)

    const [formData, setFormData] = useState({
        company_id: itemData?.company_id || '',
        companyUser_id: itemData?.companyUser_id || '',
        ticketTitle: itemData?.ticketTitle || '',
        ticketDescription: itemData?.ticketDescription || '',
        ticketStatus: itemData?.ticketStatus || '',
        ticketType: itemData?.ticketType || 'general',
        files: itemData?.files || [],
    })

    const ticketTypeList = [
        { value: 'general', label: 'General' },
        { value: 'postulacion', label: 'Postulación' },
    ]

    const handleFormChange = (event) => {

        const { name, value } = event.target

        setFormData({
            ...formData,
            [name]: value
        })

    }

    const handleFormSave = async () => {
        let newFormData = {
            ...formData,
            ticketStatus: 'borrador',
            application_id: formData.ticketType === 'postulacion' ? formData.application_id : null,
        }
        try {
            await ticketService.update(itemData.id, newFormData)
            setShowSaveModal(true)
        } catch (error) {
            setErrorMessage(error.response.data.messages.error)
            setShowErrorModal(true)
        }

    }

    const handleFormSaveAndSend = async () => {

        let newFormData = {
            ...formData,
            ticketStatus: 'en revisión',
            application_id: formData.ticketType === 'postulacion' ? formData.application_id : null,
        }
        try {
            await ticketService.update(itemData.id, newFormData)
            setCallbackData({ destination: "/tickets" })
            setShowSaveAndSendModal(true)
        } catch (error) {
            setErrorMessage(error.response.data.messages.error)
            setShowErrorModal(true)
        }
    }

    const handleCallbackOnHide = (data) => {
        navigateTo(data.destination)
    }
    const handleSendResponse = async () => {
        let newFormData = {
            ticket_id: itemData.id,
            companyUser_id: itemData.companyUser_id,
            user_id: null,
            ticketResponseSource: 'empresa',
            ticketResponseData: formData.ticketResponseData,
        }
        try {
            await ticketResponseService.create(newFormData)
            handleResponseReload()
            setShowSendResponseModal(true)
            setFormData({
                ...formData,
                ticketResponseData: '',
            })
        } catch (error) {
            setErrorMessage(error.response.data.messages.error)
            setShowErrorModal(true)
        }
    }

    const handleSendResponseClose = async () => {
        let newFormData = {
            ticket_id: itemData.id,
            companyUser_id: itemData.companyUser_id,
            ticketResponseSource: 'empresa',
            ticketResponseData: formData.ticketResponseData,
        }
        try {
            await ticketResponseService.create(newFormData)
            await ticketService.update(
                itemData.id,
                {
                    companyUser_id: itemData.companyUser_id,
                    ticketStatus: 'resuelto',
                }
            )
            navigateTo('/tickets')
        } catch (error) {
            setErrorMessage(error.response.data.messages.error)
            setShowErrorModal(true)
        }
    }

    const handleResponseReload = async () => {
        const result = await ticketResponseService.byTicket(itemData.id)
        setDynamicFormList(result)
    }


    const handleFileUpload = async (event) => {

        try {
            if (event.target.files.length > 0) {
                let fileName = event.target.files[0].name

                const newFile = await fileService.create({
                    file: event.target.files[0],
                })

                if (newFile?.error === true) {
                    alert("Error al subir el archivo: " + newFile.message)
                    return
                }

                let newTicketFile = await ticketFileService.create({
                    ticket_id: itemData.id,
                    file_id: newFile.id
                })

                const ticketFilesResult = await ticketFileService.byTicket(itemData.id)
                let newFiles = [...ticketFilesResult]
                setFormData({
                    ...formData,
                    files: newFiles
                })

            }
        } catch (error) {
            alert("Error al subir el archivo")
        }
    }

    const handleFileDownload = async (item) => {
        const fileResult = await fileService.get(item.file_id)
        if (!fileResult || !fileResult.fileBase64) return alert(
            fileResult.fileErrorMessage
        )
        const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
        const downloadLink = document.createElement("a")
        downloadLink.href = linkSource
        downloadLink.download = item.file.fileName
        downloadLink.click()
    }

    const handleFileDelete = async (item) => {
        await ticketFileService.remove(item.id)
        let newFiles = [...formData.files]
        newFiles.splice(newFiles.findIndex((file) => file.id === item.id), 1)
        setFormData({
            ...formData,
            files: newFiles
        })
    }

    useEffect(() => {
    }, [formData])

    useEffect(() => {
        if (itemData) {
            setFormData({
                ...formData,
                company_id: itemData?.company_id,
                companyUser_id: itemData?.companyUser_id,
                ticketTitle: itemData?.ticketTitle,
                ticketDescription: itemData?.ticketDescription,
                ticketStatus: itemData?.ticketStatus,
                ticketType: itemData?.ticketType,
                application_id: itemData?.application_id,
            })
            setDynamicFormList(itemData?.ticketResponses)
        }
    }, [])

    useEffect(() => {
        if (formData.ticketType === 'postulacion' && formData?.application_id === null) {
            setFormData({
                ...formData,
                application_id: itemData.applications[0].id
            })
        }
    }, [formData])

    useEffect(() => {
        if (dynamicFormList.length > 0) {
        }
    }, [dynamicFormList])

    return (
        <>
            <main className="theme-light">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12 order-2 order-lg-1 d-flex align-items-center justify-content-between">
                            <div className='d-flex justify-content-start'>
                                {
                                    <div className="btn-group mt-lg-0 mt-4 me-3">
                                        <button className="btn btn-outline-primary px-3 py-2" type="button" onClick={
                                            () => {
                                                navigateTo(backRoute)
                                            }
                                        }>
                                            <i className="fas fa-arrow-left"></i>
                                        </button>
                                    </div>
                                }
                                <h3 className="page-title fw-bold mb-lg-0 mb-4">
                                    {formTitle}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card bg-fondo-suave">
                                <div className="card-body p-4">
                                    <form action="">
                                        <div className="row">
                                            <div className="col-md-12 px-md-4 px-0">
                                                {
                                                    formData.ticketStatus === 'en revisión' && <>
                                                        <div className="form-outline mb-4">
                                                            <h4 className="text-primary">
                                                                {
                                                                    formData.ticketType === 'postulacion' ? 'Consulta de postulación' : 'Consulta general'
                                                                }: {formData.ticketTitle}
                                                            </h4>
                                                            {
                                                                formData.ticketType === 'postulacion' && <>
                                                                    <p>
                                                                        {itemData.application.applicationTitle}
                                                                    </p>
                                                                </>
                                                            }
                                                            <hr />
                                                        </div>
                                                        <div className="form-outline mb-4">
                                                            <label className="form-label" htmlFor="">Descripción</label>
                                                            <p className="text-muted">
                                                                {formData.ticketDescription}
                                                            </p>
                                                        </div>
                                                        {
                                                            formData.files.length > 0 &&
                                                            <div className="form-outline mb-4">
                                                                <>
                                                                    <label className="form-label" htmlFor="">Adjuntos</label>
                                                                    {
                                                                        formData.files.map((item, index) => {
                                                                            return (
                                                                                <div className="mb-3" key={index}>
                                                                                    <div className='d-flex justify-content-start align-items-center'>
                                                                                        <a
                                                                                            onClick={
                                                                                                () => handleFileDownload(item)
                                                                                            }
                                                                                            className="react-link"
                                                                                        >
                                                                                            {item.file.fileName}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        )
                                                                    }
                                                                </>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {
                                                    formData.ticketStatus !== 'en revisión' && <>
                                                        <div className="form-outline mb-4">
                                                            <label className="form-label" htmlFor="">Tipo de Ticket</label>
                                                            <select
                                                                className="form-select form-select-lg"
                                                                id="ticketType"
                                                                name="ticketType"
                                                                value={formData.ticketType}
                                                                onChange={handleFormChange}
                                                                disabled={readOnly}
                                                            >
                                                                {
                                                                    ticketTypeList.map((item, index) => (
                                                                        <option key={index} value={item.value}>{item.label}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            formData.ticketType === 'postulacion' && <>
                                                                <div className="form-outline mb-4">
                                                                    <label className="form-label" htmlFor="">Postulación</label>
                                                                    <select
                                                                        className="form-select form-select-lg"
                                                                        id="application_id"
                                                                        name="application_id"
                                                                        value={formData.application_id || ''}
                                                                        onChange={handleFormChange}
                                                                        disabled={readOnly}
                                                                    >
                                                                        {
                                                                            itemData.applications.map(
                                                                                (item, index) => {
                                                                                    let applicationDate = new Date(item.created_at.date)
                                                                                    //format
                                                                                    let day = applicationDate.getDate()
                                                                                    //prefix with ceroes
                                                                                    if (day < 10) {
                                                                                        day = `0${day}`
                                                                                    }
                                                                                    let month = applicationDate.getMonth() + 1
                                                                                    //prefix with ceroes
                                                                                    if (month < 10) {
                                                                                        month = `0${month}`
                                                                                    }
                                                                                    let year = applicationDate.getFullYear()
                                                                                    let hour = applicationDate.getHours()
                                                                                    let minutes = applicationDate.getMinutes()
                                                                                    let seconds = applicationDate.getSeconds()
                                                                                    //prefix with ceroes
                                                                                    if (hour < 10) {
                                                                                        hour = `0${hour}`
                                                                                    }
                                                                                    //prefix with ceroes
                                                                                    if (minutes < 10) {
                                                                                        minutes = `0${minutes}`
                                                                                    }
                                                                                    //prefix with ceroes
                                                                                    if (seconds < 10) {
                                                                                        seconds = `0${seconds}`
                                                                                    }
                                                                                    let date = `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`
                                                                                    return (
                                                                                        <option key={index} value={item.id}>[{date}] {item.applicationTitle}</option>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="form-outline mb-4">
                                                            <label className="form-label" htmlFor="">Título del Ticket</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-lg rounded-pill"
                                                                placeholder="Escriba el título del ticket"
                                                                id="ticketTitle"
                                                                name="ticketTitle"
                                                                value={formData.ticketTitle}
                                                                onChange={handleFormChange}
                                                                disabled={readOnly}
                                                            />
                                                        </div>
                                                        <div className="form-outline mb-4">
                                                            <label className="form-label" htmlFor="">Descripción</label>
                                                            <textarea
                                                                cols="30"
                                                                rows="5"
                                                                className="form-control form-control-lg"
                                                                placeholder="Describe el problema"
                                                                id="ticketDescription"
                                                                name="ticketDescription"
                                                                value={formData.ticketDescription}
                                                                onChange={handleFormChange}
                                                                disabled={readOnly}
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <div className="form-outline mb-4">
                                                            <label className="form-label" htmlFor="">Adjuntos</label>
                                                            {
                                                                !readOnly && <div className="input-group mb-3">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control form-control-lg rounded-pill"
                                                                        id="ticketFile"
                                                                        name="ticketFile"
                                                                        accept='.pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .gif'
                                                                        value={``}
                                                                        onChange={handleFileUpload}
                                                                        disabled={readOnly}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="form-outline mb-4">
                                                            {
                                                                formData.files.length > 0 &&

                                                                formData.files.map((item, index) => {
                                                                    return (
                                                                        <div className="mb-3" key={index}>
                                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                                {
                                                                                    !readOnly && <a
                                                                                        onClick={
                                                                                            () => handleFileDelete(item)
                                                                                        }
                                                                                        className='react-link btn btn-outline-danger me-4'
                                                                                    >
                                                                                        <i className="bi bi-trash"></i>
                                                                                    </a>
                                                                                }
                                                                                <a
                                                                                    onClick={
                                                                                        () => handleFileDownload(item)
                                                                                    }
                                                                                    className="react-link"
                                                                                >
                                                                                    {item.file.fileName}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        {
                                            !readOnly && formData.ticketStatus !== 'en revisión' && <div className="d-flex justify-content-end">
                                                <button
                                                    className="btn btn-outline-primary px-3 py-2 me-2"
                                                    type="button"
                                                    onClick={handleFormSave}
                                                >
                                                    Guardar cambios
                                                </button>
                                                <button
                                                    className="btn btn-send px-3 py-2"
                                                    type="button"
                                                    onClick={
                                                        () => {
                                                            setShowPreOperationModal(true)
                                                        }
                                                    }
                                                >
                                                    Guardar y Enviar
                                                </button>
                                            </div>
                                        }
                                        {
                                            !readOnly
                                            && formData.ticketStatus === 'en revisión'
                                            && dynamicFormList?.length === 0
                                            && <div className="d-flex justify-content-end">
                                                <button
                                                    className="btn btn-send px-3 py-2"
                                                    type="button"
                                                    onClick={
                                                        () => {
                                                            setShowRespondModal(true)
                                                        }
                                                    }
                                                >
                                                    Responder
                                                </button>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        dynamicFormList.length > 0 && dynamicFormList.map((item, index) => {
                            let formattedDate = new Date(item.created_at.date)
                            let formattedDateStr = formattedDate.toLocaleDateString()
                            return <div className="row mt-4" key={index}>
                                <div className="col-12">
                                    <div className="card bg-fondo-suave">
                                        <div className="card-body p-4">
                                            <div className="row">
                                                <div className="col-md-12 px-md-4 px-0">
                                                    {
                                                        <>
                                                            <div className="form-outline mb-4 d-flex justify-content-between">
                                                                <h5 className="my-0">
                                                                    {
                                                                        item.user_id && ` Administración: ${item.user?.userFullName}`
                                                                    }
                                                                    {
                                                                        item.companyUser_id && ` Postulante: ${item.company_user?.companyUserFullName}`
                                                                    }
                                                                </h5>
                                                                <p className="my-0">
                                                                    {
                                                                        formattedDateStr
                                                                    }
                                                                </p>
                                                            </div>
                                                            <hr />
                                                            <div className="form-outline mb-4">
                                                                <p className="text-default">
                                                                    {item.ticketResponseData}
                                                                </p>
                                                            </div>
                                                            <hr />

                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                !readOnly
                                                && formData.ticketStatus === 'en revisión'
                                                && index === (dynamicFormList.length - 1)
                                                && <div className="d-flex justify-content-end">
                                                    <button
                                                        className="btn btn-send px-3 py-2"
                                                        type="button"
                                                        onClick={
                                                            () => {
                                                                setShowRespondModal(true)
                                                            }
                                                        }
                                                    >
                                                        Responder
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
            </main>
            <ModalComponent
                id='respondModal'
                title={'Responder'}
                show={showRespondModal}
                setShow={setShowRespondModal}
                modalSize='modal-lg'
            >
                <div className='d-flex justify-content-between mt-4'>
                    <textarea
                        cols="30"
                        rows="5"
                        className="form-control form-control-lg"
                        placeholder="Escribe tu respuesta"
                        id="ticketResponseData"
                        name="ticketResponseData"
                        value={formData.ticketResponseData}
                        onChange={handleFormChange}
                    >
                    </textarea>
                </div>
                <div className='d-flex justify-content-between mt-4 float-end'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowRespondModal(false)
                            handleSendResponse()
                        }}
                    >
                        Enviar respuesta
                    </button>
                    <button
                        type='button'
                        className='btn btn-outline-secondary ms-2'
                        onClick={() => {
                            setShowRespondModal(false)
                            handleSendResponseClose()
                        }}
                    >
                        Enviar respuesta y cerrar ticket
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='sendResponseModal'
                title={'Respuesta enviada'}
                show={showSendResponseModal}
                setShow={setShowSendResponseModal}
            >
                <div className='d-flex justify-content-between mt-4'>
                    <p>
                        El ticket ha sido contestado con éxito.
                    </p>
                </div>
                <div className='d-flex justify-content-between mt-4 float-end'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowSendResponseModal(false)
                        }}
                    >
                        Continuar
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='saveModal'
                title={saveModalTitle}
                show={showSaveModal}
                setShow={setShowSaveModal}
            >
                <div className='d-flex justify-content-between mt-4'>
                    <p>
                        El ticket ha sido guardado con éxito.
                    </p>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowSaveModal(false)
                        }}
                    >
                        Continuar
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='saveAndSendModal'
                title={saveAndSendModalTitle}
                show={showSaveAndSendModal}
                setShow={setShowSaveAndSendModal}
                callbackOnHide={handleCallbackOnHide}
                callbackData={callbackData}
            >
                <div className='d-flex justify-content-between mt-4'>
                    <p>
                        El ticket ha sido guardado y enviado con éxito.
                    </p>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowSaveAndSendModal(false)
                        }}
                    >
                        Continuar
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='errorModal'
                title={errorModalTitle}
                show={showErrorModal}
                setShow={setShowErrorModal}
                headerClass='bg-danger text-white'
                modalSize='modal-lg'
            >
                <p>{errorMessage}</p>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowErrorModal(false)
                        }}
                    >
                        Ok
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='preOperationModal'
                title={preOperationModalTitle}
                show={showPreOperationModal}
                setShow={setShowPreOperationModal}
                headerClass='bg-primary text-white'
                modalSize='modal-lg'
            >
                <p>{preOperationModalMessage}</p>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowPreOperationModal(false)
                        }}
                    >
                        No
                    </button>
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={() => {
                            setShowPreOperationModal(false)
                            handleFormSaveAndSend()
                        }}
                    >
                        Sí
                    </button>
                </div>
            </ModalComponent>
        </>
    )
}
