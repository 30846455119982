import React, { useEffect, useState } from 'react'
import IMG_ISOTIPO_CONCURSO from '../../assets/img/isotipo-concurso.png'
import { useNavigate } from 'react-router-dom';
import sessionUtils from '../../utilities/SessionHandler'
import companyUserService from '../../services/companyUsers';
import ModalComponent from '../../components/Private/ModalComponent';

export default function SignOn() {

    const navigateTo = useNavigate()

    const handleRemoveCredentials = () => {
        sessionUtils.removeCredentials()
    }

    const btnPasswordRequest = () => {
        navigateTo('/password-request')
    }

    useEffect(() => {
        handleRemoveCredentials();
    }, []);

    return (

        <>
            <main id="welcome">
                <div className="container-fluid vh-100">
                    <div className="row">
                        <div className="col-md-5 d-flex align-items-center p-md-5 px-3 pt-5 pb-0 welcome-col">
                            <div className="welcome-title mx-auto text-md-start text-center">
                                <img src={IMG_ISOTIPO_CONCURSO}
                                    alt="Concurso Buenas Practicas"
                                    className="img-fluid mb-md-5 mb-3 isotipo-concurso"
                                />
                                <h1 className="fw-light text-white mx-auto">Bienvenido al <span className="fw-medium">Concurso Compartiendo Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex align-items-center sign-in px-lg-5 pt-3 pb-5">
                            <div className="card no-shadow m-auto p-4">
                                <h3 className="card-title fw-bold mx-auto">Bienvenido</h3>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <button
                                            className='btn btn-lg btn-primary rounded-pill w-100 my-3 py-2 text-white'
                                            onClick={() => {
                                                navigateTo('/sign-in')
                                            }}
                                        >
                                            Iniciar Sesión
                                        </button>
                                    </div>
                                    <div className="mb-3">
                                        <p>
                                            ¿Eres nuevo/a?
                                        </p>
                                    </div>
                                    <div className="mb-3">
                                        <button
                                            className='btn btn-lg btn-outline-secondary rounded-pill w-100 my-3 py-2'
                                            onClick={() => {
                                                navigateTo('/sign-up')
                                            }}
                                        >
                                            Registrarte
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
