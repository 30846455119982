import React, { useEffect, useState } from 'react'
import IMG_ISOTIPO_CONCURSO from '../../assets/img/isotipo-concurso.png'
import { useNavigate } from 'react-router-dom';
import sessionUtils from '../../utilities/SessionHandler'
import stateService from '../../services/states';
import cityService from '../../services/cities';
import companyService from '../../services/companies';
import companyUserService from '../../services/companyUsers';
import ModalComponent from '../../components/Private/ModalComponent';

export default function SignIn() {

  const navigateTo = useNavigate()
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalBody, setModalBody] = useState('')

  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])

  const [formData, setFormData] = useState(
    {
      companyUserFullName: '',
      companyUserDNI: '',
      companyUserPhone: '',
      companyUserEmail: '',
      companyUserPassword: '',
      companyName: '',
      companyDNI: '',
      state_id: 1,
      city_id: 1,
      companyAddress: '',
      companyManagerName: '',
      companyManagerPhone: '',
      companyManagerEmail: '',
      'g-recaptcha-response': ''
    }
  )

  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_RECAPTCHA_KEY, { action: "signup" })
        .then(token => {
          setRecaptchaToken(token)
          setFormData({
            ...formData,
            'g-recaptcha-response': token
          })
        })
    })
  }

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=" + process.env.REACT_APP_SITE_RECAPTCHA_KEY
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  }, [])

  const handleRemoveCredentials = () => {
    sessionUtils.removeCredentials()
  }

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleStateListLoad = async () => {
    const result = await stateService.list();
    setStateList(result)
    setFormData({
      ...formData,
      state_id: result[0].id
    })
  }

  const handleCityListLoad = async (state_id) => {
    const result = await cityService.byStates(state_id);
    setCityList(result)
  }

  const handleSubmit = async () => {
    try {

      let newFormData = {
        ...formData,
        'g-recaptcha-response': recaptchaToken
      }

      const result = await companyUserService.signUp(newFormData);

      if (result.status === 200) {
        sessionUtils.setCredentials(result.user)
        navigateTo('/home')
      }

      handleLoaded()

    } catch (error) {
      handleLoaded()
      setModalTitle('Error')
      setModalBody(error.response.data.messages.error)
      setShowModal(true)
    }
  }

  const renderSignInForm = () => {
    //set a gutter between the columns
    return (
      <>
        <div className='row'>
          <div className="col-md-6 col-sm-12">
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyName"
              >
                Nombre de la Empresa
              </label>
              <input
                type="text"
                id="companyName"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese el nombre de la empresa"
                name="companyName"
                onChange={handleInputChange}
                value={formData.companyName}
              />
            </div>
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyDNI"
              >
                RUT de la Empresa
              </label>
              <input
                type="text"
                id="companyDNI"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese el RUT de la empresa"
                name="companyDNI"
                onChange={handleInputChange}
                value={formData.companyDNI}
              />
            </div>
            {
              stateList !== '' && stateList.length > 0 && <div className="form-outline mb-4">
                <label
                  className="form-label"
                  htmlFor="state_id"
                >
                  Región
                </label>
                <select
                  id="state_id"
                  className="form-control form-control-lg rounded-pill"
                  name="state_id"
                  onChange={handleInputChange}
                  value={formData.state_id}
                >
                  {stateList.map((state) => (
                    <option
                      key={state.id}
                      value={state.id}
                    >
                      {state.stateName}
                    </option>
                  ))}
                </select>
              </div>
            }
            {
              cityList != '' && cityList.length > 0 && <div className="form-outline mb-4">
                <label
                  className="form-label"
                  htmlFor="city_id"
                >
                  Comuna
                </label>
                <select
                  id="city_id"
                  className="form-control form-control-lg rounded-pill"
                  name="city_id"
                  onChange={handleInputChange}
                  value={formData.city_id}
                >
                  {cityList.map((city) => (
                    <option
                      key={city.id}
                      value={city.id}
                    >
                      {city.cityName}
                    </option>
                  ))}
                </select>
              </div>
            }
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyAddress"
              >
                Dirección
              </label>
              <input
                type="text"
                id="companyAddress"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese la dirección de la empresa"
                name="companyAddress"
                onChange={handleInputChange}
                value={formData.companyAddress}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyUserFullName"
              >
                Nombre Completo
              </label>
              <input
                type="text"
                id="companyUserFullName"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese su nombre completo"
                name="companyUserFullName"
                onChange={handleInputChange}
                value={formData.companyUserFullName}
              />
            </div>
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyUserDNI"
              >
                RUT
              </label>
              <input
                type="text"
                id="companyUserDNI"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese su RUT"
                name="companyUserDNI"
                onChange={handleInputChange}
                value={formData.companyUserDNI}
              />
            </div>
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyUserPhone"
              >
                Teléfono
              </label>
              <input
                type="text"
                id="companyUserPhone"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese su teléfono"
                name="companyUserPhone"
                onChange={handleInputChange}
                value={formData.companyUserPhone}
              />
            </div>
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyUserEmail"
              >
                Correo Electrónico
              </label>
              <input
                type="email"
                id="companyUserEmail"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese su correo electrónico"
                name="companyUserEmail"
                onChange={handleInputChange}
                value={formData.companyUserEmail}
              />
            </div>
            <div className="form-outline mb-4">
              <label
                className="form-label"
                htmlFor="companyUserPassword"
              >
                Contraseña
              </label>
              <input
                type="password"
                id="companyUserPassword"
                className="form-control form-control-lg rounded-pill"
                placeholder="Ingrese su contraseña"
                name="companyUserPassword"
                onChange={handleInputChange}
                value={formData.companyUserPassword}
              />
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className="col-md-6 mx-auto">
            <input
              type="button"
              className="btn btn-lg btn-primary rounded-pill w-100 text-white"
              value="Registrar"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    handleRemoveCredentials();
    handleStateListLoad();
  }, []);

  useEffect(() => {
    setFormData(
      {
        ...formData,
        companyManagerEmail: formData.companyUserEmail,
        companyManagerName: formData.companyUserFullName,
        companyManagerPhone: formData.companyUserPhone
      }
    )
  }, [
    formData.companyUserFullName,
    formData.companyUserEmail,
    formData.companyUserPhone
  ]);

  useEffect(() => {
    if (formData.state_id !== '' && formData.state_id !== undefined) {
      handleCityListLoad(formData.state_id)
    }
  }, [formData.state_id]);

  return (
    <>
      <main id="welcome">
        <div className="container-fluid vh-100">
          <div className="row">
            <div className="col-md-5 d-flex align-items-center p-md-5 px-3 pt-5 pb-0 signup-col">

            </div>
            <div className="col-md-7 d-flex align-items-center sign-up px-lg-5 pt-3 pb-5">
              <div className="card no-shadow m-auto p-4">
                <h3 className="card-title fw-bold mx-auto">Crear una cuenta</h3>
                <div className="card-body">
                  <form action="">
                    {renderSignInForm()}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ModalComponent
        id='alertModal'
        title={modalTitle}
        show={showModal}
        setShow={setShowModal}
      >
        <p>
          {modalBody}
        </p>
        <div className='d-flex justify-content-end mt-4'>
          <button
            type='button'
            className='btn btn-secondary rounded-pill'
            onClick={() => {
              setShowModal(false)
            }}
          >
            Aceptar
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
